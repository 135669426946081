.ContactContantsBlock {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 50px; }
  .ContactContantsBlock__content {
    margin-left: 10px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .ContactContantsBlock {
      margin-bottom: 15px; }
      .ContactContantsBlock__img {
        width: 23px; } }
  @media (min-width: 560px) and (max-width: 767px) {
    .ContactContantsBlock {
      margin-bottom: 15px; }
      .ContactContantsBlock__img {
        width: 23px; } }
  @media (min-width: 320px) and (max-width: 559px) {
    .ContactContantsBlock {
      margin-bottom: 15px; }
      .ContactContantsBlock__img {
        width: 20px; } }

