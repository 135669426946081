.contactsBlock{
  display: flex;
  @media (min-width: 768px) and (max-width: 1024px) {
    display: block;
  }
  @media (min-width: 560px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 320px) and (max-width: 559px) {
    display: block;
  }
}