$bg_color_active: #000000;
$bg_color:#ED2E38;

.section-services-cases {
    position: relative;

    &__title {
        position: relative;
        z-index: 2;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(48, 10px);
        grid-template-rows: repeat(63, 10px);
        gap: 10px;
        margin-top: 50px;
        position: relative;
        z-index: 2;
    }

    &__button {
        text-decoration: none;
        width: 230px;
        height: 250px;
        background: $bg_color;
        border: 1px solid $bg_color;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        align-items: end;
        justify-content: center;
        position: relative;
        background-image: url(./imgs/logo.svg);
        background-repeat: no-repeat;
        background-position: 0 15px;

        &:hover {
            box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
            background: $bg_color_active;
            border: 1px solid $bg_color;
            background-image: url(./imgs/logo.svg);
            background-repeat: no-repeat;
            background-position: 0 15px;
        }

        &_text {
            font-style: normal;
            font-weight: 400;
            font-size: 22px !important;
            line-height: 150%;
            font-family: 'Avenir Next' !important;
            color: #FFFFFF;
            width: 175px;
            height: 23px;
            display: flex;
            align-items: center;
            margin-top: auto;
            margin-bottom: 15px;

            &_arrow {
                display: inline-block;
                background: url(./imgs/Arrow.svg) center no-repeat;
                height: 100%;
                width: 35px;
                margin-left: 10px;
            }
        }
    }

    &__logo {
        background: url(./imgs/background.svg) center no-repeat;
        background-size: contain;
        position: absolute;
        width: 80%;
        height: 140px;
        top: -7px;
        left: -380px;
    }

    @media screen and (min-width: 1280px) and (max-width: 1439px) {
        &__button {
            height: 230px;
        }

        &__logo {
            left: -300px;
        }

        &__container {
            grid-template-columns: repeat(44, 10px);
            grid-template-rows: repeat(61, 10px);
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        &__button {
            height: 140px;
            width: 130px;
            background-size: 15%;

            &:hover {
                background-size: 15%;
            }

            &_text {
                font-size: 12px !important;
                width: 120px;
                margin-bottom: 5px;
            }
        }

        &__logo {
            left: -270px;
        }

        &__container {
            grid-template-columns: repeat(35, 10px);
            grid-template-rows: repeat(45, 10px);
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        &__button {
            height: 140px;
            width: 150px;
            background-size: 15%;

            &:hover {
                background-size: 15%;
            }

            &_text {
                font-size: 12px !important;
                width: 120px;
                margin-bottom: 5px;
            }
        }

        &__logo {
            left: -50px;
            width: 65%;
        }

        &__container {
            grid-template-columns: repeat(32, 10px);
            grid-template-rows: repeat(40, 10px);
        }
    }

    @media screen and (min-width:425px) and (max-width:767px) {
        &__button {
            height: 110px;
            width: 154px;
            background-size: 15%;

            &:hover {
                background-size: 15%;
            }

            &_text {
                font-size: 12px !important;
                width: 120px;
                margin-bottom: 5px;
            }
        }

        &__logo {
            top: -40px;
            left: -5px;
            width: 100%;
        }

        &__container {
            grid-template-columns: repeat(14, 10px);
            grid-template-rows: repeat(19, 10px);
        }
    }

    @media screen and (min-width:375px) and (max-width:424px) {
        &__button {
            height: 110px;
            width: 105px;
            background-size: 15%;

            &:hover {
                background-size: 15%;
            }

            &_text {
                font-size: 12px !important;
                width: 120px;
                margin-bottom: 5px;

                &_arrow {
                    background-size: contain;
                    width: 20px;
                }
            }
        }

        max-width: 365px;

        &__logo {
            top: -40px;
            left: -5px;
            width: 100%;
        }

        &__container {
            grid-template-columns: repeat(14, 10px);
            grid-template-rows: repeat(19, 10px);
        }
    }

    @media screen and (min-width: 320px) and (max-width: 374px) {
        &__button {
            height: 110px;
            width: 105px;
            background-size: 15%;

            &:hover {
                background-size: 15%;
            }

            &_text {
                font-size: 12px !important;
                width: 120px;
                margin-bottom: 5px;

                &_arrow {
                    background-size: contain;
                    width: 20px;
                }
            }
        }
        &__logo {
            top: -40px;
            left: -25px;
            width: 120%;
        }

        &__container {
            grid-template-columns: repeat(14, 10px);
            grid-template-rows: repeat(19, 10px);
        }
    }
}