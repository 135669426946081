.case{
    position: relative;
    z-index: 100;
    cursor: pointer;
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
    background-size: 100% !important;
    @media screen and (min-width: 425px) and (max-width:767px) {
        height:130px;
        margin-bottom: 10px;
    }
    @media screen and (min-width: 375px) and (max-width:424px) {
        height:130px;
        margin-bottom: 10px;
    }
    @media screen and (min-width: 320px) and (max-width:374px) {
        height:130px;
        margin-bottom: 10px;
    }
}