.personCard{
  margin-right: 2rem;
  .cardPreview{
    width: 13rem;
    height: 13rem;
    background-position: center;
    background-size: cover !important;
    .redBG{
    }
  }
  &__name{
    margin-top: 0.75rem;
  }
  &__post{
    color: #FFFFFF;
    margin-top: 0.125rem;
  }
  @media (min-width: 1280px) and (max-width: 1439px){
    margin-right: 1.5rem;
    .cardPreview {
      width: 13rem;
      height: 14rem;
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px){
    margin-right: 1.5rem;
    .cardPreview {
      width: 10rem;
      height: 14rem;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px){
    width: 25%;
    margin-right: 0;
    .cardPreview {
      height: 12rem;
      width: auto;
    }
  }
}