.whyUs {
  max-width: 960px;
  position: relative; }
  .whyUs__logo {
    position: absolute;
    background: url(./imgs/logo.svg) center no-repeat;
    background-size: contain;
    width: 103%;
    height: 223px;
    top: 0;
    left: -380px; }
  .whyUs .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 3rem;
    /* &__img{
      width: 33%;
      background: url("./imgs/code.svg") center no-repeat;
      background-size: contain;
    } */ }
    .whyUs .content__leftBlock {
      width: 45%;
      z-index: 2; }
    .whyUs .content__rightBlock {
      width: 45%;
      z-index: 2; }
  @media (min-width: 1280px) and (max-width: 1439px) {
    .whyUs {
      max-width: 896px; }
      .whyUs__logo {
        left: -300px; } }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .whyUs {
      max-width: 704px; }
      .whyUs__logo {
        left: -270px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .whyUs {
      max-width: 640px; }
      .whyUs__logo {
        left: -50px;
        width: 80%; } }
  @media screen and (min-width: 425px) and (max-width: 767px) {
    .whyUs {
      max-width: 415px; }
      .whyUs__logo {
        top: -40px;
        left: -5px;
        width: 100%; }
      .whyUs .content {
        margin-top: 20px;
        display: block; }
        .whyUs .content__leftBlock {
          width: 100%;
          position: relative; }
        .whyUs .content__img {
          height: 200px;
          background: url("./imgs/codeMobile.svg") center no-repeat;
          background-size: contain;
          margin-top: 10px;
          width: 100%; }
        .whyUs .content__rightBlock {
          width: 100%; } }
  @media screen and (min-width: 375px) and (max-width: 424px) {
    .whyUs {
      max-width: 365px; }
      .whyUs__logo {
        top: -40px;
        left: -5px;
        width: 100%; }
      .whyUs .content {
        margin-top: 20px;
        display: block; }
        .whyUs .content__leftBlock {
          width: 100%;
          position: relative; }
        .whyUs .content__img {
          height: 200px;
          background: url("./imgs/codeMobile.svg") center no-repeat;
          background-size: contain;
          margin-top: 10px;
          width: 100%; }
        .whyUs .content__rightBlock {
          width: 100%; } }
  @media screen and (min-width: 320px) and (max-width: 374px) {
    .whyUs {
      max-width: 280px; }
      .whyUs__logo {
        top: -40px;
        left: -5px;
        width: 100%; }
      .whyUs .content {
        margin-top: 20px;
        display: block; }
        .whyUs .content__leftBlock {
          width: 100%;
          position: relative; }
        .whyUs .content__img {
          height: 200px;
          background: url("./imgs/codeMobile.svg") center no-repeat;
          background-size: contain;
          margin-top: 10px;
          width: 100%; }
        .whyUs .content__rightBlock {
          width: 100%; } }

