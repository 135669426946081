.services-solutions-menu {
    $red: #FF0F0F;
    width: 300px;
    max-height: 510px;
    min-height: 170px;
    position: sticky;
    top: 62px;
    left: 0;
    z-index: 2;

    margin-right: 15px;

    &__subtitle {
        margin-top: 15px;
    }

    &__price {
        margin-top: 15px;

        &_red {
            color: $red;
            position: relative;

            &::after {
                content: '';
                background: url(./imgs/rub.svg) center no-repeat;
                background-size: contain;
                right: -35px;
                bottom: 5px;
                width: 30px;
                height: 30px;
                display: inline-block;
                position: absolute;

            }
        }
    }

    @media screen and (min-width:1280px) and (max-width:1439px) {
        width: 262px;
        max-height: 505px;
        min-height: 196px;
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {
        width: 220px;
        max-height: 513px;
        min-height: 210px;
        margin-right: 15px;

        &__subtitle {
            margin-top: 5px;
        }

        &__price {
            &_red {
                &::after {
                    right: -35px;
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        width: 216px;
        max-height: 564px;
        min-height: 212px;

        &__subtitle {
            margin-top: 17px;
        }

        &__price {
            &_red {
                &::after {
                    right: -30px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    @media screen and (min-width:425px) and (max-width:767px) {
        width: 415px;
        position: relative;
        top: 0;
        margin-bottom: 20px;

        &__subtitle {
            margin-top: 10px;
        }

        &__price {
            &_red {
                &::after {
                    right: -25px;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    @media screen and (min-width:375px) and (max-width:424px) {
        position: relative;
        width: 365px;
        top: 0;
        margin-bottom: 20px;

        &__subtitle {
            margin-top: 10px;
        }

        &__price {
            &_red {
                &::after {
                    right: -25px;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    @media screen and (min-width:320px) and (max-width:374px) {
        position: relative;
        max-width: 275px;
        min-height: 100%;
        top: 0;
        margin-bottom: 20px;

        &__subtitle {
            margin-top: 10px;
        }

        &__price {
            &_red {
                &::after {
                    right: -25px;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}