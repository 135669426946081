.input {
  background: none;
  border: 1px solid #FFF;
  border-radius: 2px;
  padding: 6px 0 6px 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 100%;
  display: block;
  width: 235px;
  height: 40px;
  color: white;
  overflow: hidden; }
  .input__container {
    height: 70px;
    position: relative; }
  .input__error {
    font-family: "Avenir Next";
    position: absolute;
    color: #ED2E38;
    font-weight: 300;
    font-size: 12px;
    line-height: 110%;
    bottom: 10px; }
  .input::-webkit-input-placeholder {
    color: #7C7C7C;
    font-size: 13px; }
  .input:-ms-input-placeholder {
    color: #7C7C7C;
    font-size: 13px; }
  .input::-ms-input-placeholder {
    color: #7C7C7C;
    font-size: 13px; }
  .input::placeholder {
    color: #7C7C7C;
    font-size: 13px; }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .input {
      width: 100%; } }

