.menu__container {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0;
  pointer-events: none; }
  .menu__container_open {
    pointer-events: fill;
    -webkit-animation: show 0.8s ease-out 1 forwards;
            animation: show 0.8s ease-out 1 forwards; }

.close {
  -webkit-animation: cls 0.5s ease-in 1 forwards;
          animation: cls 0.5s ease-in 1 forwards; }

.menu {
  max-width: 250px;
  height: 585px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 75px;
  z-index: 2; }
  .menu__close {
    background: url(./imgs/close.svg) center no-repeat;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -2px;
    right: 130px; }
  .menu__logo {
    background: url(./imgs/logo.svg) center no-repeat;
    background-size: cover;
    width: 190px;
    height: 17px; }
  .menu__links {
    margin: 46px 0 184px; }
  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    .menu {
      max-width: 220px; }
      .menu__logo {
        width: 170px;
        height: 15px; } }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    .menu {
      max-width: 200px; }
      .menu__logo {
        width: 140px;
        height: 13px; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .menu {
      display: none; }
      .menu__container {
        background: #000000; }
      .menu__logo {
        background-size: contain; }
      .menu_tablet {
        top: 25px;
        left: 64px;
        position: relative; } }
  @media screen and (min-width: 425px) and (max-width: 767px) {
    .menu__close {
      right: 100px; } }
  @media screen and (min-width: 375px) and (max-width: 424px) {
    .menu__close {
      right: 80px; } }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .menu {
      display: none; }
      .menu__container {
        background: #000000; }
      .menu__logo {
        background-size: contain; }
      .menu_tablet {
        top: 25px;
        left: 64px;
        position: relative; } }
@-webkit-keyframes show {
  0% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }
@keyframes show {
  0% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }
@-webkit-keyframes cls {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    opacity: 0; } }
@keyframes cls {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    opacity: 0; } }

