.services-solutions-menu {
  width: 300px;
  max-height: 510px;
  min-height: 170px;
  position: -webkit-sticky;
  position: sticky;
  top: 62px;
  left: 0;
  z-index: 2;
  margin-right: 15px; }
  .services-solutions-menu__subtitle {
    margin-top: 15px; }
  .services-solutions-menu__price {
    margin-top: 15px; }
    .services-solutions-menu__price_red {
      color: #FF0F0F;
      position: relative; }
      .services-solutions-menu__price_red::after {
        content: '';
        background: url(./imgs/rub.svg) center no-repeat;
        background-size: contain;
        right: -35px;
        bottom: 5px;
        width: 30px;
        height: 30px;
        display: inline-block;
        position: absolute; }
  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    .services-solutions-menu {
      width: 262px;
      max-height: 505px;
      min-height: 196px; } }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    .services-solutions-menu {
      width: 220px;
      max-height: 513px;
      min-height: 210px;
      margin-right: 15px; }
      .services-solutions-menu__subtitle {
        margin-top: 5px; }
      .services-solutions-menu__price_red::after {
        right: -35px;
        width: 25px;
        height: 25px; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .services-solutions-menu {
      width: 216px;
      max-height: 564px;
      min-height: 212px; }
      .services-solutions-menu__subtitle {
        margin-top: 17px; }
      .services-solutions-menu__price_red::after {
        right: -30px;
        width: 20px;
        height: 20px; } }
  @media screen and (min-width: 425px) and (max-width: 767px) {
    .services-solutions-menu {
      width: 415px;
      position: relative;
      top: 0;
      margin-bottom: 20px; }
      .services-solutions-menu__subtitle {
        margin-top: 10px; }
      .services-solutions-menu__price_red::after {
        right: -25px;
        width: 15px;
        height: 15px; } }
  @media screen and (min-width: 375px) and (max-width: 424px) {
    .services-solutions-menu {
      position: relative;
      width: 365px;
      top: 0;
      margin-bottom: 20px; }
      .services-solutions-menu__subtitle {
        margin-top: 10px; }
      .services-solutions-menu__price_red::after {
        right: -25px;
        width: 15px;
        height: 15px; } }
  @media screen and (min-width: 320px) and (max-width: 374px) {
    .services-solutions-menu {
      position: relative;
      max-width: 275px;
      min-height: 100%;
      top: 0;
      margin-bottom: 20px; }
      .services-solutions-menu__subtitle {
        margin-top: 10px; }
      .services-solutions-menu__price_red::after {
        right: -25px;
        width: 15px;
        height: 15px; } }

