$bg_color:#ED2E38;
$bg_color_active:#000000;
$color_text: #FFFFFF;

.shop {
    align-self: start;
    margin-left: 0 !important;
    margin-top: 8px;
    background: $bg_color_active !important;
    border: 1px solid $bg_color;

    &:hover {
        background: $bg_color !important;
        border: 1px solid $bg_color;
    }
}

.button {
    width: 190px;
    height: 40px;
    background: $bg_color;
    border: 1px solid $bg_color;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    border-radius: 2px;
    margin-left: auto;

    &:hover {
        box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
        background: $bg_color_active;
        border: 1px solid $bg_color;
    }

    &__img {
        background: url(./imgs/img.svg) center no-repeat;
        width: 34px;
        height: 32px;
        position: absolute;
        left: -9.5px;
    }

    &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 15px !important;
        line-height: 150%;
        color: $color_text;
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        width: 134px;
        height: 34px;

        &__img {
            background-size: contain;
            width: 25px;
            height: 23px;
            left: -6.5px;
        }
    }

    @media screen and (min-width:320px) and (max-width:767px) {
        width: 134px;
        height: 34px;

        &__img {
            background-size: contain;
            width: 25px;
            height: 23px;
            left: -6.5px;
        }
    }
}

.end {
    margin-left: auto;

    @media screen and (min-width:320px) and (max-width:767px) {
        align-self: center;
        width: 110px;
    }
}
.shop {
    width: 190px;
}
.start {
    align-self: start;
    margin-left: 0;
}

.burger {
    width: 190px;
    align-self: start;
    margin-left: 0;
}