.service-page {
  max-width: 1290px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 100px; }
  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    .service-page {
      max-width: 1170px; } }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    .service-page {
      max-width: 960px; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .service-page {
      max-width: 640px; } }
  @media screen and (min-width: 425px) and (max-width: 767px) {
    .service-page {
      max-width: 415px; } }
  @media screen and (min-width: 375px) and (max-width: 424px) {
    .service-page {
      max-width: 365px; } }
  @media screen and (min-width: 320px) and (max-width: 374px) {
    .service-page {
      max-width: 280px;
      padding-bottom: 57px; } }

