.slide{
  display: flex;
  max-width: 870px;
  margin: 0 auto;
  &__preview{
    max-width: 260px;
    object-fit: contain;
  }
  &-content{
    margin-left: 50px;
    &-header{
      display: flex;
      &__stage{
        white-space: nowrap;
        margin: auto 0;
        background-color: #FF0F0F;
        padding: 5px;
      }
      &__title{
        margin-left: 15px;
      }
    }
    &-description{
      margin-top: 30px;
      &__upperText{

      }
      &__lowerText{
        margin-top: 10px;
      }
    }
  }
}