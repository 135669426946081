.partners {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .partners__container {
    display: grid;
    grid-template-columns: 190px 190px 190px;
    grid-template-rows: 50px 50px 50px;
    row-gap: 20px;
    -webkit-column-gap: 30px;
            column-gap: 30px; }
    .partners__container .partner {
      background-size: cover !important;
      row-gap: 8px;
      -webkit-column-gap: 13px;
              column-gap: 13px; }
  @media (min-width: 1280px) and (max-width: 1439px) {
    .partners {
      max-width: 879px; }
      .partners__container {
        grid-template-columns: 139px 139px 139px;
        grid-template-rows: 37px 37px 37px; } }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .partners {
      max-width: 704px; }
      .partners__container {
        grid-template-columns: 139px 139px 139px;
        grid-template-rows: 37px 37px 37px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .partners {
      max-width: 640px; }
      .partners__container {
        grid-template-columns: 127px 127px 127px;
        grid-template-rows: 34px 34px 34px; } }
  @media (min-width: 250px) and (max-width: 767px) {
    .partners {
      max-width: 280px;
      display: block; }
      .partners__container {
        margin-top: 20px;
        grid-template-columns: 85px 85px 85px;
        grid-template-rows: 23px 23px 23px;
        row-gap: 6px;
        -webkit-column-gap: 10px;
                column-gap: 10px; } }

