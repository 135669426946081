.Contacts {
  background-color: #000000;
  color: #FFFFFF; }
  .Contacts .footer {
    border-top: 2px solid #ED2E38;
    padding-top: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .Contacts .footer .leftBlock {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .Contacts .footer .leftBlock .RR {
        font-size: 12px !important; }
      .Contacts .footer .leftBlock .logo {
        background: url("./imgs/logo.svg") center no-repeat;
        background-size: cover;
        width: 4rem;
        height: 4rem; }
    .Contacts .footer .links__item {
      display: block;
      text-decoration: none;
      margin-top: 0.625rem; }
    .Contacts .footer .links__item:nth-child(1) {
      margin-top: 0; }
    .Contacts .footer .feedback {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .Contacts .footer .feedback .socialNetwrks {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse; }
        .Contacts .footer .feedback .socialNetwrks .linksToSN {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
        .Contacts .footer .feedback .socialNetwrks__item {
          margin-right: 1rem;
          width: 30px;
          height: 30px;
          cursor: pointer; }
          .Contacts .footer .feedback .socialNetwrks__item_telegram {
            background: url("./imgs/telegram.svg") center no-repeat;
            background-size: cover; }
          .Contacts .footer .feedback .socialNetwrks__item_telegram:hover {
            background: url("./imgs/telegramHover.svg") center no-repeat;
            background-size: cover;
            border: 1px solid #ED2E38;
            border-radius: 50px;
            -webkit-box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
                    box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4); }
          .Contacts .footer .feedback .socialNetwrks__item_behance {
            background: url("./imgs/behance.svg") center no-repeat;
            background-size: cover;
            border-radius: 50px; }
          .Contacts .footer .feedback .socialNetwrks__item_behance:hover {
            background: url("./imgs/behanceHover.svg") center no-repeat;
            background-size: cover;
            border: 1px solid #ED2E38;
            border-radius: 50px;
            -webkit-box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
                    box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4); }
          .Contacts .footer .feedback .socialNetwrks__item_whats {
            background: url("./imgs/whats.svg") center no-repeat;
            background-size: cover;
            border-radius: 50px; }
          .Contacts .footer .feedback .socialNetwrks__item_whats:hover {
            background: url("./imgs/whatsHover.svg") center no-repeat;
            background-size: cover;
            border: 1px solid #ED2E38;
            border-radius: 50px;
            -webkit-box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
                    box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4); }
        .Contacts .footer .feedback .socialNetwrks__mail {
          color: white;
          text-decoration: none;
          font-family: Russo One;
          -ms-flex-item-align: center;
              align-self: center; }
      .Contacts .footer .feedback__main {
        margin-top: 3rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: end; }
      .Contacts .footer .feedback__phone {
        color: white;
        text-decoration: none;
        text-align: end;
        font-family: Russo One; }
      .Contacts .footer .feedback__address {
        text-align: end; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .Contacts {
      max-width: 640px; }
      .Contacts .footer .feedback .socialNetwrks {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .Contacts .footer .feedback .socialNetwrks .linksToSN {
          margin-top: 10px;
          -ms-flex-item-align: end;
              align-self: end; } }
  @media screen and (min-width: 425px) and (max-width: 767px) {
    .Contacts .footer {
      max-width: 415px; }
      .Contacts .footer .leftBlock .RR {
        font-size: 8px !important; }
      .Contacts .footer .feedback .socialNetwrks {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-left: auto; }
        .Contacts .footer .feedback .socialNetwrks__item:last-child {
          margin-right: 0; }
        .Contacts .footer .feedback .socialNetwrks .linksToSN {
          margin-top: 10px;
          -ms-flex-item-align: end;
              align-self: end;
          margin-left: auto; }
        .Contacts .footer .feedback .socialNetwrks__mail {
          -ms-flex-item-align: end;
              align-self: end; }
      .Contacts .footer .links {
        display: none; } }
  @media screen and (min-width: 375px) and (max-width: 424px) {
    .Contacts .footer {
      max-width: 365px; }
      .Contacts .footer .leftBlock .RR {
        font-size: 8px !important; }
      .Contacts .footer .feedback .socialNetwrks {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-left: auto; }
        .Contacts .footer .feedback .socialNetwrks__item:last-child {
          margin-right: 0; }
        .Contacts .footer .feedback .socialNetwrks .linksToSN {
          margin-top: 10px;
          -ms-flex-item-align: end;
              align-self: end;
          margin-left: auto; }
        .Contacts .footer .feedback .socialNetwrks__mail {
          -ms-flex-item-align: end;
              align-self: end; }
      .Contacts .footer .links {
        display: none; } }
  @media screen and (min-width: 320px) and (max-width: 374px) {
    .Contacts .footer {
      max-width: 280px; }
      .Contacts .footer .leftBlock .RR {
        font-size: 8px !important; }
      .Contacts .footer .feedback .socialNetwrks {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-left: auto; }
        .Contacts .footer .feedback .socialNetwrks__item:last-child {
          margin-right: 0; }
        .Contacts .footer .feedback .socialNetwrks .linksToSN {
          margin-top: 10px;
          -ms-flex-item-align: end;
              align-self: end;
          margin-left: auto; }
        .Contacts .footer .feedback .socialNetwrks__mail {
          -ms-flex-item-align: end;
              align-self: end; }
      .Contacts .footer .links {
        display: none; } }

