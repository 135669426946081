.rocket_active {
  display: block;
  width: 30px;
  height: 30px;
  background: #ED2E38;
  border-radius: 2px;
  background-image: url("./imgs/arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: fixed;
  right: 2%;
  bottom: 50px;
  -webkit-animation: scroll 0.3s 1 ease-in forwards;
          animation: scroll 0.3s 1 ease-in forwards;
  z-index: 1000; }
@-webkit-keyframes scroll {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }
@keyframes scroll {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

