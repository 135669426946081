.ContactContantsBlock{
  text-decoration: none;
  display: flex;
  margin-right: 50px;
  &__img{

  }
  &__content{
    margin-left: 10px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 15px;
    &__img{
      width: 23px;
    }
  }
  @media (min-width: 560px) and (max-width: 767px) {
    margin-bottom: 15px;
    &__img{
      width: 23px;
    }
  }
  @media (min-width: 320px) and (max-width: 559px) {
    margin-bottom: 15px;
    &__img{
      width: 20px;
    }
  }
}