$border_color:#FFF;
$placeholder_color:#7C7C7C;

.input {
    background: none;
    border: 1px solid $border_color;
    border-radius: 2px;
    padding: 6px 0 6px 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 100%;
    display: block;
    width: 235px;
    height: 40px;
    color: white;
    overflow: hidden;

    &__container {
        height: 70px;
        position: relative;
    }

    &__error {
        font-family: "Avenir Next";
        position: absolute;
        color: #ED2E38;
        font-weight: 300;
        font-size: 12px;
        line-height: 110%;
        bottom: 10px;
    }

    &::placeholder {
        color: $placeholder_color;
        font-size: 13px;
    }

    @media screen and (min-width:320px) and (max-width:767px) {
        width: 100%;
    }
}