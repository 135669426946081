$color:#FFFFFF;
$bglink:#ED2E38;

.link {
    font-family: 'Avenir Next';
    max-width: 250px;
    margin-top: 25px;
    cursor: pointer;

    &:first-child {
        margin: 0;
    }

    &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 1em;
        color: $color;
        display: flex;
        align-items: center;
        text-decoration: none;

        &:before {
            content: "";
            display: inline-block;
            height: 1px;
            width: 80px;
            margin-right: 30px;
            background: $bglink;
            transition: all 0.5s ease-out;
        }

        &_active {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 1em;
            color: $color;
            display: flex;
            align-items: center;
            text-decoration: none;

            &::before {
                content: "";
                display: inline-block;
                width: 130px;
                height: 3px;
                background: $bglink;
                margin-right: 15px;
                transition: all 0.5s ease-out;
            }
        }

        /* &_line {
            
            display: inline-block;
            height: 1px;
            background: #ED2E38;
            width:80px;
            margin-right: 30px;
            background:$bglink;
            transition: all 0.5s ease-out;
        }
        &_line_active{
            width:130px;
            height: 2px;
            background: #ED2E38;
            margin-right: 15px;
            transition: all 0.3s ease-out;
        }  */
    }

    @media screen and (min-width:1280px) and (max-width:1439px) {
        &__text {
            &:before {
                width: 70px;
                margin-right: 27px;
            }

            &_active {
                &::before {
                    width: 100px;
                }
            }
        }

        /*  &__text {
            &_line {
                width: 70px;
                margin-right: 27px;
            }

            &_line_active {
                width: 100px;
            }
        } */
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {

        /* &__text {
            &_line {
                width: 50px;
                margin-right: 22px;
            }

            &_line_active {
                width: 85px;
            }
        } */
        &__text {
            &:before {
                width: 50px;
                margin-right: 22px;
            }

            &_active {
                &::before {
                    width: 85px;
                }
            }
        }
    }
}