.not-found {
    background: #000;
    padding-top: 10%;

    &__content {
        max-width: 1290px;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 50px;
        margin-top: 67px;
        z-index: 2;
    }

    &__text {
        max-width: 520px;
        margin-bottom: 30px;
        z-index: 2;
    }

    &__social {
        &_text {
            font-family: 'Russo One' !important;
            margin-bottom: 15px;
        }
    }

    &__links {
        display: flex;
    }

    &__link {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-top: 15px;
        background-size: contain;
        margin-right: 15px;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
            border: 1px solid #ED2E38;
        }

        &:last-child {
            margin-right: 0;
        }

        &_telegram {
            background: url(./imgs/telegram.svg) center no-repeat;

            &:hover {
                background: url(./imgs/telegramHover.svg) center no-repeat;
            }
        }

        &_whats {
            background: url(./imgs/whats.svg) center no-repeat;

            &:hover {
                background: url(./imgs/whatsHover.svg) center no-repeat;
            }
        }

        &_behance {
            background: url(./imgs/behance.svg) center no-repeat;

            &:hover {
                background: url(./imgs/behanceHover.svg) center no-repeat;
            }
        }
    }

    &__logo {
        width: 495px;
        height: 495px;
        background: url(./imgs/logo.svg)center no-repeat;
        position: absolute;
        top: 0;
        right: 0;
    }

    @media screen and (min-width:1280px) and (max-width:1439px) {
        &__content {
            max-width: 1170px;
        }
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {
        padding-top: 15%;

        &__content {
            max-width: 940px;
        }

        &__logo {
            width: 438px;
            height: 438px;
            background-size: contain;
        }
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        padding-top: 20%;

        &__content {
            max-width: 638px;
        }

        &__title {
            margin-top: 50px;
        }

        &__logo {
            width: 369px;
            height: 369px;
            background-size: contain;
        }
    }

    @media screen and (min-width:320px) and (max-width:767px) {
        padding-top: 25%;

        &__content {
            max-width: 280px;
            overflow: hidden;
        }

        &__title {
            margin-bottom: 30px;
        }

        &__text {
            line-height: 120% !important;
        }

        &__link {
            margin-top: 10px;
        }

        &__logo {
            width: 247px;
            height: 247px;
            background-size: contain;
            right: -123px;
            top: 35px;
        }
    }
}