.section-our-cases {
  max-width: 960px;
  margin-top: 200px !important;
  position: relative; }
  .section-our-cases__title {
    z-index: 2;
    position: relative; }
  .section-our-cases__subtitle {
    position: relative;
    z-index: 2;
    margin-bottom: 10px; }
    .section-our-cases__subtitle:first-child {
      margin-top: 15px; }
  .section-our-cases__container {
    display: grid;
    grid-template-columns: repeat(6, 151px);
    grid-template-rows: repeat(2, 167px);
    gap: 10px;
    margin-top: 40px; }
  .section-our-cases__button {
    text-decoration: none;
    background: #ED2E38;
    border: 1px solid #ED2E38;
    border-radius: 2px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    background-image: url(./imgs/logo.svg);
    background-repeat: no-repeat;
    background-position: -20px 5px; }
    .section-our-cases__button:hover {
      -webkit-box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
              box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
      background: #000000;
      border: 1px solid #ED2E38;
      background-image: url(./imgs/logo.svg);
      background-repeat: no-repeat;
      background-position: -20px 5px; }
    .section-our-cases__button_text {
      font-style: normal;
      font-weight: 400;
      font-size: 15px !important;
      line-height: 150%;
      font-family: 'Avenir Next' !important;
      color: #FFFFFF;
      width: 133px;
      height: 23px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-top: auto;
      margin-bottom: 7px; }
      .section-our-cases__button_text_arrow {
        display: inline-block;
        background: url(./imgs/Arrow.svg) center no-repeat;
        height: 100%;
        width: 35px;
        margin-left: 10px; }
  .section-our-cases__logo {
    background: url(./imgs/background.svg) center no-repeat;
    background-size: contain;
    position: absolute;
    width: 80%;
    height: 140px;
    top: 10px;
    left: -380px; }
  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    .section-our-cases {
      max-width: 872px; }
      .section-our-cases__logo {
        left: -300px; }
      .section-our-cases__container {
        grid-template-columns: repeat(6, 137px);
        grid-template-rows: repeat(2, 147px); } }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    .section-our-cases {
      max-width: 702px; }
      .section-our-cases__logo {
        left: -270px; }
      .section-our-cases__container {
        grid-template-columns: repeat(6, 110px);
        grid-template-rows: repeat(2, 147px); }
      .section-our-cases__button_text {
        font-size: 10px !important;
        width: 97px;
        height: 16px;
        margin-bottom: 5px; }
        .section-our-cases__button_text_arrow {
          width: 26px;
          margin-left: 7px;
          background-size: contain; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .section-our-cases {
      max-width: 640px;
      margin-top: 100px !important; }
      .section-our-cases__logo {
        left: -50px;
        width: 65%; }
      .section-our-cases__container {
        grid-template-columns: repeat(6, 101px);
        grid-template-rows: repeat(2, 108px);
        gap: 7px; }
      .section-our-cases__button {
        background-image: url(./imgs/logo_tablet.svg);
        background-size: 25%;
        background-position: 0 5px; }
        .section-our-cases__button:hover {
          background-image: url(./imgs/logo_tablet.svg);
          background-position: 0 5px;
          background-size: 25%; }
        .section-our-cases__button_text {
          font-size: 10px !important;
          width: 97px;
          height: 16px;
          margin-bottom: 5px; }
          .section-our-cases__button_text_arrow {
            width: 26px;
            margin-left: 7px;
            background-size: contain; } }
  @media screen and (min-width: 425px) and (max-width: 767px) {
    .section-our-cases {
      max-width: 415px;
      margin-top: 60px !important; }
      .section-our-cases__logo {
        top: -40px;
        left: -5px;
        width: 100%; }
      .section-our-cases__subtitle {
        margin-top: 20px;
        margin-bottom: 20px; }
      .section-our-cases__container {
        grid-template-columns: repeat(3, 131.5px);
        grid-template-rows: repeat(3, 120px);
        gap: 10px; }
      .section-our-cases__button {
        background-image: url(./imgs/logo_tablet.svg);
        background-position: 0 5px;
        background-size: 20%; }
        .section-our-cases__button:hover {
          background-image: url(./imgs/logo_tablet.svg);
          background-position: 0 5px;
          background-size: 20%; }
        .section-our-cases__button_text {
          font-size: 12px !important;
          width: 100px;
          height: 15px;
          margin-bottom: 5px; }
          .section-our-cases__button_text_arrow {
            width: 20px;
            margin-left: 6px;
            background-size: contain; } }
  @media screen and (min-width: 375px) and (max-width: 424px) {
    .section-our-cases {
      max-width: 365px;
      margin-top: 60px !important; }
      .section-our-cases__logo {
        top: -40px;
        left: -5px;
        width: 100%; }
      .section-our-cases__subtitle {
        margin-top: 20px;
        margin-bottom: 20px; }
      .section-our-cases__container {
        grid-template-columns: repeat(3, 114.5px);
        grid-template-rows: repeat(3, 120px);
        gap: 10px; }
      .section-our-cases__button {
        background-image: url(./imgs/logo_tablet.svg);
        background-position: 0 5px;
        background-size: 20%; }
        .section-our-cases__button:hover {
          background-image: url(./imgs/logo_tablet.svg);
          background-position: 0 5px;
          background-size: 20%; }
        .section-our-cases__button_text {
          font-size: 12px !important;
          width: 100px;
          height: 15px;
          margin-bottom: 5px; }
          .section-our-cases__button_text_arrow {
            width: 20px;
            margin-left: 6px;
            background-size: contain; } }
  @media screen and (min-width: 320px) and (max-width: 374px) {
    .section-our-cases {
      max-width: 280px;
      margin-top: 60px !important; }
      .section-our-cases__logo {
        top: -40px;
        left: -5px;
        width: 100%; }
      .section-our-cases__subtitle {
        margin-top: 20px;
        margin-bottom: 20px; }
      .section-our-cases__container {
        grid-template-columns: repeat(3, 86.5px);
        grid-template-rows: repeat(3, 120px);
        gap: 10px; }
      .section-our-cases__button {
        background-image: url(./imgs/logo_tablet.svg);
        background-position: 0 5px;
        background-size: 23%; }
        .section-our-cases__button:hover {
          background-image: url(./imgs/logo_tablet.svg);
          background-position: 0 5px;
          background-size: 23%; }
        .section-our-cases__button_text {
          font-size: 10px !important;
          width: 78px;
          height: 15px;
          margin-bottom: 5px; }
          .section-our-cases__button_text_arrow {
            width: 15px;
            margin-left: 6px;
            background-size: contain; } }

