.brief {
   margin-bottom: 30px;
   max-width: 500px;

   &__title {
      font-weight: 700 !important;
      margin-bottom: 5px;
   }

   &__subtitle {
      font-size: 18px !important;
      opacity: 0.7;
   }

   @media screen and (min-width:1280px) and (max-width:1439px) {
      max-width: 420px;
   }

   @media screen and (min-width: 1024px) and (max-width: 1279px) {
      max-width: 354px;

      &__title {
         font-weight: 700 !important;
         font-size: 18px !important;
      }

      &__subtitle {
         font-size: 18px !important;
      }
   }
}