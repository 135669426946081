.section-services-card {
  width: 477px;
  height: 240px;
  position: relative;
  border-radius: 2px;
  border: 0.1px solid transparent;
  -o-border-image: linear-gradient(to left top, #ed2e38 0%, transparent 100%, transparent 100%, #ed2e38 90%);
     border-image: -webkit-gradient(linear, right bottom, left top, from(#ed2e38), color-stop(100%, transparent), color-stop(100%, transparent), color-stop(90%, #ed2e38));
     border-image: linear-gradient(to left top, #ed2e38 0%, transparent 100%, transparent 100%, #ed2e38 90%);
  border-image-slice: 19; }
  .section-services-card__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    top: 25px;
    left: 0;
    padding: 6px 0;
    margin-left: 1.5rem; }
    .section-services-card__title::before {
      margin-right: 1.25rem;
      content: url("./imgs/Face.svg");
      width: 40px;
      height: 40px; }
  .section-services-card__arrow {
    width: 35px;
    height: 38px;
    background: url(./imgs/arrow.svg) center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 15px;
    right: 22px;
    cursor: pointer; }
  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    .section-services-card {
      width: 433px;
      height: 218px;
      /* &__title {
          max-width: 230px;
      } */ }
      .section-services-card__arrow {
        width: 35px;
        height: 38px; } }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    .section-services-card {
      width: 349px;
      height: 175px; }
      .section-services-card__arrow {
        width: 25px;
        height: 25px; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .section-services-card {
      width: 315px;
      height: 175px; }
      .section-services-card__title {
        font-size: 14px !important;
        top: 10px; }
      .section-services-card__arrow {
        width: 30px;
        height: 30px;
        right: 10px;
        bottom: 7px; } }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .section-services-card {
      width: 100%;
      height: 140px; }
      .section-services-card__title {
        /* width: 165px; */
        font-size: 14px !important;
        top: 18px; }
      .section-services-card__arrow {
        width: 18px;
        height: 18px;
        right: 14px;
        bottom: 10px; } }

