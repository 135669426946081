.section-services-cases-card {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    cursor: pointer;
    position: relative;

    &__info {
        position: absolute;
        top: 23px;
        left: 9px;
        display: flex;
        flex-direction: column;
        max-width: 167px;
    }

    &__text {
        &:last-child {
            margin-top: 30px;
        }
    }

    &:nth-child(1) {
        grid-column: span 16;
        grid-row: span 16;
        background: url(./imgs/1_1920.png) center no-repeat;
        background-size: 100%;
    }

    &:nth-child(2) {
        grid-column: span 16;
        grid-row: span 18;
        background: url(./imgs/2_1920.png) center no-repeat;
        background-size: 100%;

    }

    &:nth-child(3) {
        grid-column: span 15;
        grid-row: span 20;
        background: url(./imgs/3_1920.png) center no-repeat;
        background-size: 100%;
    }

    &:nth-child(4) {
        grid-column: span 16;
        grid-row: span 15;
        background: url(./imgs/4_1920.png) center no-repeat;
        background-size: 100%;
    }

    &:nth-child(5) {
        grid-column: span 16;
        grid-row: span 13;
        background: url(./imgs/5_1920.png) center no-repeat;
        background-size: 100%;
    }

    &:nth-child(6) {
        grid-column: span 15;
        grid-row: span 16;
        border: 1px solid #ED2E38;
    }

    &:nth-child(7) {
        grid-column: span 18;
        grid-row: span 15;
        background: url(./imgs/7_1920.png) center no-repeat;
        background-size: 100%;
    }

    &:nth-child(8) {
        grid-column: span 14;
        grid-row: span 17;
        border: 1px solid #ED2E38;
    }

    &:nth-child(9) {
        grid-column: span 15;
        grid-row: span 12;
        border: 1px solid #ED2E38;
    }

    &:nth-child(10) {
        grid-column: span 18;
        grid-row: span 15;
        border: 1px solid #ED2E38;
    }

    &:nth-child(11) {
        grid-column: span 17;
        grid-row: span 13;
        border: 1px solid #ED2E38;
    }
    @media screen and (min-width: 1280px) and (max-width: 1439px) {
        &:nth-child(1) {
            grid-column: span 14;
            grid-row: span 16;
            background: url(./imgs/1_1280.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(2) {
            grid-column: span 14;
            grid-row: span 18;
            background: url(./imgs/2_1280.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(3) {
            grid-column: span 14;
            grid-row: span 20;
            background: url(./imgs/3_1280.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(4) {
            grid-column: span 14;
            grid-row: span 12;
            background: url(./imgs/4_1280.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(5) {
            grid-column: span 14;
            grid-row: span 11;
            background: url(./imgs/5_1280.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(6) {
            grid-column: span 14;
            grid-row: span 15;
        }
    
        &:nth-child(7) {
            grid-column: span 14;
            grid-row: span 15;
            background: url(./imgs/7_1280.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(8) {
            grid-column: span 14;
            grid-row: span 18;
        }
    
        &:nth-child(9) {
            grid-column: span 14;
            grid-row: span 12;
        }
    
        &:nth-child(10) {
            grid-column: span 14;
            grid-row: span 16;
        }
    
        &:nth-child(11) {
            grid-column: span 16;
            grid-row: span 12;
        }
    }
    
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        &__info{
            top:18px;
        }
        &:nth-child(1) {
            grid-column: span 13;
            grid-row: span 14;
            background: url(./imgs/1_1024.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(2) {
            grid-column: span 13;
            grid-row: span 15;
            background: url(./imgs/2_1024.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(3) {
            grid-column: span 9;
            grid-row: span 17;
            background: url(./imgs/3_1024.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(4) {
            grid-column: span 13;
            grid-row: span 11;
            background: url(./imgs/4_1024.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(5) {
            grid-column: span 13;
            grid-row: span 10;
            background: url(./imgs/5_1024.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(6) {
            grid-column: span 9;
            grid-row: span 11;
        }
    
        &:nth-child(7) {
            grid-column: span 18;
            grid-row: span 15;
            background: url(./imgs/7_1024.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(8) {
            grid-column: span 8;
            grid-row: span 18;
        }
    
        &:nth-child(9) {
            grid-column: span 9;
            grid-row: span 15;
        }
    
        &:nth-child(10) {
            grid-column: span 18;
            grid-row: span 16;
        }
    
        &:nth-child(11) {
            grid-column: span 10;
            grid-row: span 13;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        &__info{
            top:11px;
        }
        &:nth-child(1) {
            grid-column: span 11;
            grid-row: span 11;
            background: url(./imgs/1_768.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(2) {
            grid-column: span 11;
            grid-row: span 12;
            background: url(./imgs/2_768.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(3) {
            grid-column: span 10;
            grid-row: span 14;
            background: url(./imgs/3_768.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(4) {
            grid-column: span 11;
            grid-row: span 11;
            background: url(./imgs/4_768.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(5) {
            grid-column: span 11;
            grid-row: span 10;
            background: url(./imgs/5_768.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(6) {
            grid-column: span 10;
            grid-row: span 15;
        }
    
        &:nth-child(7) {
            grid-column: span 13;
            grid-row: span 15;
            background: url(./imgs/7_768.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(8) {
            grid-column: span 9;
            grid-row: span 17;
        }
    
        &:nth-child(9) {
            grid-column: span 10;
            grid-row: span 10;
        }
    
        &:nth-child(10) {
            grid-column: span 13;
            grid-row: span 16;
        }
    
        &:nth-child(11) {
            grid-column: span 11;
            grid-row: span 14;
        }
    }
    @media screen and (min-width: 425px) and (max-width: 767px){
        &__info{
            top:10px;
        }
        &:nth-child(1) {
            grid-column: span 15;
            grid-row: span 6;
            background: url(./imgs/1_425.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(2) {
            grid-column: span 15;
            grid-row: span 6;
            background: url(./imgs/2_425.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(3) {
            display: none;
        }
    
        &:nth-child(4) {
            display: none;
        }
    
        &:nth-child(5) {
            display: none;
        }
    
        &:nth-child(6) {
            display: none;
        }
    
        &:nth-child(7) {
            grid-column: span 13;
            grid-row: span 6;
            background: url(./imgs/7_425.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(8) {
            display: none;
        }
    
        &:nth-child(9) {
            display: none;
        }
    
        &:nth-child(10) {
            display: none;
        }
    
        &:nth-child(11) {
            display: none;
        }
    } 
    @media screen and (min-width: 375px) and (max-width: 424px){
        &__info{
            top:10px;
        }
        &:nth-child(1) {
            grid-column: span 15;
            grid-row: span 6;
            background: url(./imgs/1_375.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(2) {
            grid-column: span 15;
            grid-row: span 6;
            background: url(./imgs/2_375.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(3) {
            display: none;
        }
    
        &:nth-child(4) {
            display: none;
        }
    
        &:nth-child(5) {
            display: none;
        }
    
        &:nth-child(6) {
            display: none;
        }
    
        &:nth-child(7) {
            grid-column: span 13;
            grid-row: span 6;
            background: url(./imgs/7_375.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(8) {
            display: none;
        }
    
        &:nth-child(9) {
            display: none;
        }
    
        &:nth-child(10) {
            display: none;
        }
    
        &:nth-child(11) {
            display: none;
        }
    }
    @media screen and (min-width: 320px) and (max-width: 374px) {
        &__info{
            top:10px;
        }
        &:nth-child(1) {
            grid-column: span 15;
            grid-row: span 6;
            background: url(./imgs/1_320.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(2) {
            grid-column: span 15;
            grid-row: span 6;
            background: url(./imgs/2_320.png) center no-repeat;
            background-size: 100%;
        }
    
        &:nth-child(3) {
            display: none;
        }
    
        &:nth-child(4) {
            display: none;
        }
    
        &:nth-child(5) {
            display: none;
        }
    
        &:nth-child(6) {
            display: none;
        }
    
        &:nth-child(7) {
            grid-column: span 9;
            grid-row: span 6;
            background-size: 100%;
        }
    
        &:nth-child(8) {
            display: none;
        }
    
        &:nth-child(9) {
            display: none;
        }
    
        &:nth-child(10) {
            display: none;
        }
    
        &:nth-child(11) {
            display: none;
        }
    }
}