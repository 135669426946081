$border_color:#FFF;
$placeholder_color:#7C7C7C;

.modal {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.8);
    z-index: 1005;

    &_disabled {
        display: none;
    }

    &__container {
        width: 690px;
        height: 500px;
        background: white;
        box-shadow: 0px 0px 22px #ED2E38;
        position: relative;
        background: url(./imgs/form.svg) center no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        width: 487px;
        /* height: 315px; */
    }

    &__title {
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    &__subtitle {
        font-weight: 300 !important;
        font-size: 16px !important;
        line-height: 110% !important;
        margin-bottom: 30px;
    }

    .form {
        &__inputs {
            display: flex;
            justify-content: space-between;
            /* height: 120px; */

            &_left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

        }

        &__input {
            background: none;
            border: 1px solid $border_color;
            border-radius: 2px;
            padding: 6px;
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 100%;
            display: block;
            width: 235px;
            height: 180px;
            color: white;
            resize: none;
            overflow: hidden;

            &::placeholder {
                font-family: 'Avenir Next';
                color: $placeholder_color;
                font-size: 14px;
                line-height: 100%;
            }
        }

        &__buttons {
            margin-top: 40px;
            display: flex;
            align-items: center;
        }

    }

    &__confidence {
        font-weight: 300 !important;
        font-size: 12px !important;
        line-height: 110% !important;
        color: $placeholder_color !important;
        max-width: 206px;
        margin-left: 15px;
    }

    &__button {
        &_close {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            background: url(./imgs/close.svg) center no-repeat;
            background-size: cover;
            cursor: pointer;
        }
    }
    @media screen and (min-width:320px) and (max-width:900px) {
        &__container{
            overflow: auto;
        }
    }
    @media screen and (min-width:320px) and (max-width:767px) {
        height: 100%;

        &__title {
            font-size: 51px !important;
            line-height: 61px !important;
        }

        &__subtitle {
            max-width: 255px;
        }

        &__container {
            width: 320px;
            height: 678px;
            background: url(./imgs/form_mobile.svg) center no-repeat;
        }

        &__content {
            width: 277px;
        }

        .form {
            &__inputs {
                flex-direction: column;
                height: 330px;

                &_left {
                    height: 200px;
                }
            }

            &__buttons {
                margin-top: 27px;
                flex-direction: column;
                justify-content: center;
                align-items: baseline;
            }

            &__input {
                width: 100%;
            }
        }

        &__confidence {
            margin: 0;
            margin-top: 9px;
        }
    }
}