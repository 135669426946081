.contactsBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .contactsBlock {
      display: block; } }
  @media (min-width: 560px) and (max-width: 767px) {
    .contactsBlock {
      display: block; } }
  @media (min-width: 320px) and (max-width: 559px) {
    .contactsBlock {
      display: block; } }

