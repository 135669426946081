.contacts-form__input, .contacts-form__input::-webkit-input-placeholder, .contacts-form__textarea, .contacts-form__textarea::-webkit-input-placeholder, .contacts-form__add {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF; }

.contacts-form__input, .contacts-form__input:-ms-input-placeholder, .contacts-form__textarea, .contacts-form__textarea:-ms-input-placeholder, .contacts-form__add {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF; }

.contacts-form__input, .contacts-form__input::-ms-input-placeholder, .contacts-form__textarea, .contacts-form__textarea::-ms-input-placeholder, .contacts-form__add {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF; }

.contacts-form__input, .contacts-form__input::placeholder, .contacts-form__textarea, .contacts-form__textarea::placeholder, .contacts-form__add {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF; }

.contacts-form__inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
.contacts-form__input {
  display: block;
  background: none;
  border: none;
  width: 190px;
  height: 30px;
  border-bottom: 1px solid #ED2E38;
  margin-bottom: 25px;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out; }
  .contacts-form__input_error {
    position: absolute;
    color: #ED2E38;
    font-size: 10px;
    bottom: 0; }
  .contacts-form__input:focus {
    width: 410px;
    border-bottom: 1px solid #ED2E38;
    outline: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out; }
.contacts-form__brief {
  max-width: 520px;
  margin-bottom: 30px; }
.contacts-form__request {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 50px; }
.contacts-form__textarea {
  resize: none;
  overflow: hidden;
  background: none;
  border: 1px solid #ED2E38;
  width: 410px;
  padding: 10px; }
  .contacts-form__textarea:focus {
    outline: 0; }
.contacts-form__add {
  cursor: pointer;
  background: none;
  width: 220px;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 30px 0 50px; }
  .contacts-form__add:before {
    content: '';
    background: url(./imgs/add.svg) center no-repeat;
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    left: 0;
    margin-right: 10px; }
.contacts-form__confidence {
  max-width: 300px;
  font-size: 14px !important;
  line-height: 110% !important;
  margin-top: 10px; }
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .contacts-form__textarea {
    width: 300px; } }
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .contacts-form__textarea {
    width: 475px;
    height: 250px; }
  .contacts-form .brief__info {
    display: none; } }
@media screen and (min-width: 425px) and (max-width: 767px) {
  .contacts-form__input {
    font-size: 14px; }
    .contacts-form__input::-webkit-input-placeholder {
      font-size: 14px; }
    .contacts-form__input:-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__input::-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__input::placeholder {
      font-size: 14px; }
  .contacts-form__textarea {
    width: 415px;
    height: 250px;
    font-size: 14px; }
    .contacts-form__textarea::-webkit-input-placeholder {
      font-size: 14px; }
    .contacts-form__textarea:-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__textarea::-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__textarea::placeholder {
      font-size: 14px; }
  .contacts-form .brief__info {
    display: none; }
  .contacts-form__confidence {
    max-width: 200px;
    font-size: 12px !important; } }
@media screen and (min-width: 375px) and (max-width: 424px) {
  .contacts-form__input {
    font-size: 14px; }
    .contacts-form__input:focus {
      width: 340px; }
    .contacts-form__input::-webkit-input-placeholder {
      font-size: 14px; }
    .contacts-form__input:-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__input::-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__input::placeholder {
      font-size: 14px; }
  .contacts-form__textarea {
    width: 365px;
    height: 250px;
    font-size: 14px; }
    .contacts-form__textarea::-webkit-input-placeholder {
      font-size: 14px; }
    .contacts-form__textarea:-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__textarea::-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__textarea::placeholder {
      font-size: 14px; }
  .contacts-form .brief__info {
    display: none; }
  .contacts-form__confidence {
    max-width: 200px;
    font-size: 12px !important; } }
@media screen and (min-width: 320px) and (max-width: 374px) {
  .contacts-form__input {
    font-size: 14px;
    width: 150px; }
    .contacts-form__input:focus {
      width: 260px; }
    .contacts-form__input::-webkit-input-placeholder {
      font-size: 14px; }
    .contacts-form__input:-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__input::-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__input::placeholder {
      font-size: 14px; }
  .contacts-form__textarea {
    width: 280px;
    height: 250px;
    font-size: 14px; }
    .contacts-form__textarea::-webkit-input-placeholder {
      font-size: 14px; }
    .contacts-form__textarea:-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__textarea::-ms-input-placeholder {
      font-size: 14px; }
    .contacts-form__textarea::placeholder {
      font-size: 14px; }
  .contacts-form .brief__info {
    display: none; }
  .contacts-form__confidence {
    max-width: 200px;
    font-size: 12px !important; } }

