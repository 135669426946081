.section-services-cases {
  position: relative; }
  .section-services-cases__title {
    position: relative;
    z-index: 2; }
  .section-services-cases__container {
    display: grid;
    grid-template-columns: repeat(48, 10px);
    grid-template-rows: repeat(63, 10px);
    gap: 10px;
    margin-top: 50px;
    position: relative;
    z-index: 2; }
  .section-services-cases__button {
    text-decoration: none;
    width: 230px;
    height: 250px;
    background: #ED2E38;
    border: 1px solid #ED2E38;
    border-radius: 2px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    background-image: url(./imgs/logo.svg);
    background-repeat: no-repeat;
    background-position: 0 15px; }
    .section-services-cases__button:hover {
      -webkit-box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
              box-shadow: 0px 4px 29px 1px rgba(237, 46, 56, 0.4);
      background: #000000;
      border: 1px solid #ED2E38;
      background-image: url(./imgs/logo.svg);
      background-repeat: no-repeat;
      background-position: 0 15px; }
    .section-services-cases__button_text {
      font-style: normal;
      font-weight: 400;
      font-size: 22px !important;
      line-height: 150%;
      font-family: 'Avenir Next' !important;
      color: #FFFFFF;
      width: 175px;
      height: 23px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-top: auto;
      margin-bottom: 15px; }
      .section-services-cases__button_text_arrow {
        display: inline-block;
        background: url(./imgs/Arrow.svg) center no-repeat;
        height: 100%;
        width: 35px;
        margin-left: 10px; }
  .section-services-cases__logo {
    background: url(./imgs/background.svg) center no-repeat;
    background-size: contain;
    position: absolute;
    width: 80%;
    height: 140px;
    top: -7px;
    left: -380px; }
  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    .section-services-cases__button {
      height: 230px; }
    .section-services-cases__logo {
      left: -300px; }
    .section-services-cases__container {
      grid-template-columns: repeat(44, 10px);
      grid-template-rows: repeat(61, 10px); } }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    .section-services-cases__button {
      height: 140px;
      width: 130px;
      background-size: 15%; }
      .section-services-cases__button:hover {
        background-size: 15%; }
      .section-services-cases__button_text {
        font-size: 12px !important;
        width: 120px;
        margin-bottom: 5px; }
    .section-services-cases__logo {
      left: -270px; }
    .section-services-cases__container {
      grid-template-columns: repeat(35, 10px);
      grid-template-rows: repeat(45, 10px); } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .section-services-cases__button {
      height: 140px;
      width: 150px;
      background-size: 15%; }
      .section-services-cases__button:hover {
        background-size: 15%; }
      .section-services-cases__button_text {
        font-size: 12px !important;
        width: 120px;
        margin-bottom: 5px; }
    .section-services-cases__logo {
      left: -50px;
      width: 65%; }
    .section-services-cases__container {
      grid-template-columns: repeat(32, 10px);
      grid-template-rows: repeat(40, 10px); } }
  @media screen and (min-width: 425px) and (max-width: 767px) {
    .section-services-cases__button {
      height: 110px;
      width: 154px;
      background-size: 15%; }
      .section-services-cases__button:hover {
        background-size: 15%; }
      .section-services-cases__button_text {
        font-size: 12px !important;
        width: 120px;
        margin-bottom: 5px; }
    .section-services-cases__logo {
      top: -40px;
      left: -5px;
      width: 100%; }
    .section-services-cases__container {
      grid-template-columns: repeat(14, 10px);
      grid-template-rows: repeat(19, 10px); } }
  @media screen and (min-width: 375px) and (max-width: 424px) {
    .section-services-cases {
      max-width: 365px; }
      .section-services-cases__button {
        height: 110px;
        width: 105px;
        background-size: 15%; }
        .section-services-cases__button:hover {
          background-size: 15%; }
        .section-services-cases__button_text {
          font-size: 12px !important;
          width: 120px;
          margin-bottom: 5px; }
          .section-services-cases__button_text_arrow {
            background-size: contain;
            width: 20px; }
      .section-services-cases__logo {
        top: -40px;
        left: -5px;
        width: 100%; }
      .section-services-cases__container {
        grid-template-columns: repeat(14, 10px);
        grid-template-rows: repeat(19, 10px); } }
  @media screen and (min-width: 320px) and (max-width: 374px) {
    .section-services-cases__button {
      height: 110px;
      width: 105px;
      background-size: 15%; }
      .section-services-cases__button:hover {
        background-size: 15%; }
      .section-services-cases__button_text {
        font-size: 12px !important;
        width: 120px;
        margin-bottom: 5px; }
        .section-services-cases__button_text_arrow {
          background-size: contain;
          width: 20px; }
    .section-services-cases__logo {
      top: -40px;
      left: -25px;
      width: 120%; }
    .section-services-cases__container {
      grid-template-columns: repeat(14, 10px);
      grid-template-rows: repeat(19, 10px); } }

