.whyUs {
  max-width: 960px;
  position: relative;

  &__logo {
    position: absolute;
    background: url(./imgs/logo.svg) center no-repeat;
    background-size: contain;
    width: 103%;
    height: 223px;
    top: 0;
    left: -380px;
  }

  &__title {}

  .content {

    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    &__leftBlock {
      width: 45%;
      z-index: 2;
    }

    /* &__img{
      width: 33%;
      background: url("./imgs/code.svg") center no-repeat;
      background-size: contain;
    } */
    &__rightBlock {
      width: 45%;
      z-index: 2;
    }

  }

  @media (min-width: 1280px) and (max-width: 1439px) {
    max-width: 896px;

    &__logo {
      left: -300px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    max-width: 704px;

    &__logo {
      left: -270px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 640px;

    &__logo {
      left: -50px;
      width: 80%;
    }
  }
  @media screen and (min-width:425px) and (max-width:767px){
    max-width: 415px;
    &__logo {
      top:-40px;
      left: -5px;
      width: 100%;
    }

    .content {
      margin-top: 20px;
      display: block;

      &__leftBlock {
        width: 100%;
        position: relative;
      }

      &__img {
        height: 200px;
        background: url("./imgs/codeMobile.svg") center no-repeat;
        background-size: contain;
        margin-top: 10px;
        width: 100%;
      }

      &__rightBlock {
        width: 100%;
      }
    }
  }
  @media screen and (min-width:375px) and (max-width:424px){
      max-width: 365px;
      &__logo {
        top:-40px;
        left: -5px;
        width: 100%;
      }
  
      .content {
        margin-top: 20px;
        display: block;
  
        &__leftBlock {
          width: 100%;
          position: relative;
        }
  
        &__img {
          height: 200px;
          background: url("./imgs/codeMobile.svg") center no-repeat;
          background-size: contain;
          margin-top: 10px;
          width: 100%;
        }
  
        &__rightBlock {
          width: 100%;
        }
      }
      
  }
  @media screen and (min-width:320px) and (max-width:374px){
    max-width: 280px;

    &__logo {
      top:-40px;
      left: -5px;
      width: 100%;
    }

    .content {
      margin-top: 20px;
      display: block;

      &__leftBlock {
        width: 100%;
        position: relative;
      }

      &__img {
        height: 200px;
        background: url("./imgs/codeMobile.svg") center no-repeat;
        background-size: contain;
        margin-top: 10px;
        width: 100%;
      }

      &__rightBlock {
        width: 100%;
      }
    }
  }
  }