.section-services-solutions{
    display:flex;
    flex-direction:column;
    position: relative;
    &__content{
        display:flex;
        justify-content:space-between;
    }
    &__container{
        z-index: 2;
    }
    &__logo{
        position: absolute;
        left: -380px;
        width: 103%;
        pointer-events: none;
    }
    @media screen and (min-width:1280px) and (max-width:1439px) {
        &__logo{
            left: -300px;
        }
        max-width:872px;
    }
    @media screen and (min-width:1024px) and (max-width:1279px){
        max-width:702px;
        &__logo{
            left: -270px;
        }
    }
    @media screen and (min-width:768px) and (max-width:1023px){
        max-width:640px;
        &__logo{
            left: -50px;
            width: 80%;
        }
    }
    @media screen and (min-width:425px) and (max-width:767px){
        max-width: 415px;
        &__logo {
            left: -5px;
            width: 100%;
          }
        &__content{
            display:flex;
            flex-direction:column;
        }
    }
    @media screen and (min-width:375px) and (max-width:424px){
        max-width: 365px;
        &__logo {
            left: -5px;
            width: 100%;
          }
        &__content{
            display:flex;
            flex-direction:column;
        }
    }
    @media screen and (min-width:320px) and (max-width:374px){
        max-width: 280px;
        &__logo {
            top:10px;
            left: -5px;
            width: 100%;
          }
        &__content{
            display:flex;
            flex-direction:column;
        }
    }
}