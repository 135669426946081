$bg_color:#ED2E38;

.section-services-card {
    width: 477px;
    height: 240px;
    position: relative;
    border-radius: 2px;
    border: 0.1px solid transparent;
    border-image: linear-gradient(to left top,#ed2e38 0%, transparent 100%, transparent 100%, #ed2e38 90%);
    border-image-slice: 19;

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 25px;
        left: 0;
        padding: 6px 0;
        margin-left: 1.5rem;
        &::before{
            margin-right: 1.25rem;
            content: url("./imgs/Face.svg");
            width: 40px;
            height: 40px;
        }
    }

    &__arrow {
        width: 35px;
        height: 38px;
        background: url(./imgs/arrow.svg) center no-repeat;
        background-size:contain;
        position: absolute;
        bottom: 15px;
        right: 22px;
        cursor: pointer;
    }

    @media screen and (min-width:1280px) and (max-width:1439px)  {
        width: 433px;
        height: 218px;

        /* &__title {
            max-width: 230px;
        } */

        &__arrow {
            width: 35px;
            height: 38px;
        }
    }
    @media screen and (min-width:1024px) and (max-width:1279px){
        width: 349px;
        height: 175px;

        &__title {
        }

        &__arrow {
            width: 25px;
            height: 25px;
        }
    }
    @media screen and (min-width:768px) and (max-width:1023px){
        width: 315px;
        height: 175px;
        &__title {
            font-size: 14px !important;
            top: 10px;
        }
        &__arrow {
            width: 30px;
            height: 30px;
            right: 10px;
            bottom:7px;
        } 
    }
    @media screen and (min-width:320px) and (max-width:767px){
        width: 100%;
        height: 140px;

        &__title {
            /* width: 165px; */
            font-size: 14px !important;
            top: 18px;
        }

        &__arrow {
            width: 18px;
            height: 18px;
            right: 14px;
            bottom:10px;
        } 
    }
}