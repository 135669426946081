@font-face {
    font-family: "Avenir Next";
    src: url("./AvenirNextCyr-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

html,
body {
    background:black;
}
