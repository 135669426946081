$span_color:#ED2E38;
$text_color:#FFFFFF;
.header{
    max-width: 960px;
    display: flex;
    margin-top:150px !important;
    &__logo{
        background: url(./imgs/logo.svg) center no-repeat;
        background-size: cover;
        width: 410px;
        height:557px;
        position: absolute;
        top: 75px;
        right:0;
        z-index: 1;
        opacity:0.5;
    }
    &__info{
        display: flex;
        flex-direction: column;
        z-index: 4;
    }
    &__title{
        max-width: 700px;
        margin-bottom:20px;
        text-transform:uppercase !important;
        &_color{
            color: $span_color;
        }
    }
    &__line{
        border: 1px solid $span_color;
        background:$span_color;
        max-width: 630px;
        margin-bottom: 20px;
    }
    &__subtitle{
        margin-bottom:15px;
        &:last-child{
            margin: 0;
        }
    }
    @media screen and (min-width:1280px) and (max-width:1439px) {
        max-width: 870px;
        &__logo{
            width: 329px;
            height:431px;
        }
    }
    @media screen and (min-width:1024px) and (max-width:1279px){
        max-width: 702px;
        &__logo{
            width: 265px;
            height:347px;
        }
    }
    @media screen and (min-width:768px) and (max-width:1023px){
        max-width: 640px;
        margin-top:95px !important;
        &__logo{
            width: 265px;
            height:347px;
        }
    }
    @media screen and (min-width:425px) and (max-width:767px){
        max-width: 415px;
        margin-top:80px !important;
        &__logo{
            width: 184px;
            height:240px;
            top: 45px;
        }
    }
    @media screen and (min-width:375px) and (max-width:424px){
        max-width: 365px;
        margin-top:80px !important;
        &__logo{
            width: 184px;
            height:240px;
            top: 45px;
        }
    }
    @media screen and (min-width:320px) and (max-width:374px){
        max-width: 280px;
        margin-top:80px !important;
        &__logo{
            width: 184px;
            height:240px;
            top: 45px;
        }
    }
}