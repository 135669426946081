$border_color:#ED2E38;

%text {
    font-family: 'Avenir Next';
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
}

.contacts-form {
    &__inputs {
        display: flex;
        flex-direction: column;
    }

    &__input {
        display: block;
        background: none;
        border: none;
        width: 190px;
        height: 30px;
        border-bottom: 1px solid $border_color;
        margin-bottom: 25px;
        transition: all 0.5s ease-out;
        @extend %text;

        &_error {
            position: absolute;
            color: $border_color;
            font-size: 10px;
            bottom: 0;
        }

        &::placeholder {
            @extend %text;
        }

        &:focus {
            width: 410px;
            border-bottom: 1px solid $border_color;
            outline: 0;
            transition: all 0.5s ease-out;
        }
    }

    &__brief {
        max-width: 520px;
        margin-bottom: 30px;
    }

    &__request {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    &__textarea {
        resize: none;
        overflow: hidden;
        background: none;
        border: 1px solid $border_color;
        width: 410px;
        padding: 10px;
        @extend %text;

        &::placeholder {
            @extend %text;
        }

        &:focus {
            outline: 0;
        }
    }

    &__add {
        cursor: pointer;
        background: none;
        @extend %text;
        width: 220px;
        height: 26px;
        display: flex;
        align-items: center;
        margin: 30px 0 50px;

        &:before {
            content: '';
            background: url(./imgs/add.svg) center no-repeat;
            width: 26px;
            height: 26px;
            display: inline-block;
            position: relative;
            left: 0;
            margin-right: 10px;
        }
    }

    &__confidence {
        max-width: 300px;
        font-size: 14px !important;
        line-height: 110% !important;
        margin-top: 10px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        &__textarea {
            width: 300px;
        }
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        &__textarea {
            width: 475px;
            height: 250px;
        }
        .brief{
            &__info{
                display: none;
            }
        }
    }
    @media screen and (min-width:425px) and (max-width:767px) {
        &__input {
            font-size: 14px;
            &::placeholder {
                font-size: 14px;
            }
        }
        &__textarea {
            width: 415px;
            height: 250px;
            font-size: 14px;
            &::placeholder {
                font-size: 14px;
            }
        }
        .brief{
            &__info{
                display: none;
            }
        }
        &__confidence {
            max-width: 200px;
            font-size: 12px !important;
        }
    }
    @media screen and (min-width:375px) and (max-width:424px) {
        &__input {
            font-size: 14px;
            &:focus {
                width: 340px;
            }
            &::placeholder {
                font-size: 14px;
            }
        }
        &__textarea {
            width: 365px;
            height: 250px;
            font-size: 14px;
            &::placeholder {
                font-size: 14px;
            }
        }
        .brief{
            &__info{
                display: none;
            }
        }
        &__confidence {
            max-width: 200px;
            font-size: 12px !important;
        }
    }
    @media screen and (min-width:320px) and (max-width:374px) {
        &__input {
            font-size: 14px;
            width: 150px;
            &:focus {
                width: 260px;
            }
            &::placeholder {
                font-size: 14px;
            }
        }
        &__textarea {
            width: 280px;
            height: 250px;
            font-size: 14px;
            &::placeholder {
                font-size: 14px;
            }
        }
        .brief{
            &__info{
                display: none;
            }
        }
        &__confidence {
            max-width: 200px;
            font-size: 12px !important;
        }
    }
}